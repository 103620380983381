import React, { Component } from 'react';
import styled from "styled-components";

const Place = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -40px / 2;
  top: -40px / 2;

  border: 5px solid #f44336;
  border-radius: K_HEIGHT;
  background-color: white;
  text-align: center;
  color: #3f51b5;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
`;

class MapContainer extends Component {
  render() {
    return (
       <Place>
          {this.props.text}
       </Place>
    );
  }
}

export default MapContainer;