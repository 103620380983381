import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { Wrapper, SectionH2, SectionParag, Section1ColWhite } from "../styles/style.js";
import GoogleMaps from "../components/GoogleMaps.js";

const ContactPage = () => (
  <Layout>
    <Seo
      title="Contact Us"
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.9",
          height: 500,
        }}
        alt="Contact Us"
        src="../img/header-contact-bg.jpeg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <div className="text-white text-5xl md:text-7xl font-extrabold text-center">Contact Us</div>
        </div>
      </div>
    </div>

    <Section1ColWhite>
      <Wrapper>
        <SectionH2>Let's Talk!</SectionH2>
        <SectionParag>
          We want to provide the best service possible. If you need a quote or have any other questions or comments, then feel free to e-mail or call using any of the information below. You will get a response as quickly as possible.
        </SectionParag>
        <SectionParag>
          <strong>Phone:</strong> 877-615-4667 (LIMOS)
        </SectionParag>
        <SectionParag>
          <strong>Direct:</strong> 714-335-9471
        </SectionParag>
        <SectionParag>
          <strong>Fax:</strong> 714-417-9444
        </SectionParag>
        <SectionParag>
          <strong>Email:</strong> info@amazinglimos.net
        </SectionParag>
        <SectionParag>
          <strong>Address:</strong> <a href="https://goo.gl/maps/cuv31wBM6cz" className="underline text-blue-900">6260 Rutland Ave Riverside, CA 92503</a>
        </SectionParag>
      </Wrapper>
    </Section1ColWhite>

    <GoogleMaps />

    <SectionBottomCta grayBackground />
  </Layout>
);

export default ContactPage