import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import MapContainer from './MapContainer.js';

class GoogleMaps extends Component {
  static defaultProps = {
    center: [33.562298, -117.708644],
    zoom: 9,
    placeB: {lat: 33.944888, lng: -117.470855}
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAxNzNavPC4qKA8ijndvnN06MxFPwtwT70' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <MapContainer
            {...this.props.placeB}
            text={'A'}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMaps;